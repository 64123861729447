<template>
	<div class="navigation">
		<el-image src="/index/background.png" class="navigation-img"></el-image>
		
		<div class="title">
			<span class="title-span">{{headTitle}}</span>
		</div>
		
		<!-- 画布 -->
		<div class="coverage">
			<el-image class="coverage-img" src="/home/coverage.png"></el-image>
			<el-image class="coverage-newsimg" :src="headImg"></el-image>
		</div>
	</div>
</template>

<script>
	export default
	{
		data()
		{
			return{
			}
		},
		props:
		{
			headTitle:String,
			headImg:String
		},
	}
</script>

<style scoped>
	.navigation{
		width: 100%;
		position: relative;
	}
	.navigation-img{
		width: 100%;
		height: 196px;
	}
	.title{
		width: 947px;
		height: 30px;
		font-size: 30px;
		position: absolute;
		bottom: 90px;
		left: 200px;
		text-align: left;
		color: white;
		border-bottom: solid 1px #f5f5f5;
		padding-bottom: 30px;
	}
	.title-span{
		padding-bottom: 5px;
		border-bottom: solid 5px white;
		
		font-size: 48px;
		color: #FFFFFF;
	}
	.coverage{
		width: 100%;
		height: 0px;
		position: relative;
		margin-bottom: 50px;
	}
	.coverage .coverage-img{
		z-index: 9;
		position: absolute;
		width: 100%;
		left: 0px;
		bottom: 0px;
	}
	.coverage .coverage-newsimg{
		width: 52px;
		height: 52px;
		left: 48.5%;
		bottom: -20px;
		z-index: 99;
		position: absolute;
	}
</style>