<template>
	<div>
		<!-- 头部logo -->
		<headLogo @navigation="navigation"></headLogo>
		
		<!-- 头部导航 -->
		<navigation :headTitle="headTitle" :headImg="headImg" style="margin-top:96px;"></navigation>
		
		<div class="aboutUsPc">
			<el-row class="aboutUsPc-information">
				<el-row class="aboutUsPc-title">Who we are</el-row>
				<el-row>
					<el-col :span="7" :offset="1" class="aboutUsPc-data">
						<el-row class="aboutUsPc-number">{{newList.theaterNum}}</el-row>
						<el-row class="aboutUsPc-message">
							Theatres across the country
						</el-row>
						<el-row>
							<el-image class="news-chainimg" src="/home/chain.png"></el-image>
						</el-row>
					</el-col>	
					<el-col :span="7" :offset="1" class="aboutUsPc-data">
						<el-row class="aboutUsPc-number">{{newList.repertoryNum}}</el-row>
						<el-row class="aboutUsPc-message">
							Annual performances
						</el-row>
						<el-row>
							<el-image class="news-chainimg" src="/home/chain.png"></el-image>
						</el-row>
					</el-col>	
					<el-col :span="7" :offset="1" class="aboutUsPc-data">
						<el-row class="aboutUsPc-number">{{newList.viewerNum}}</el-row>
						<el-row class="aboutUsPc-message">
							Audience
						</el-row>
						<el-row>
							<el-image class="news-chainimg" src="/home/chain.png"></el-image>
						</el-row>
					</el-col>	
				</el-row>
			</el-row>
			<el-row class="values-message" v-html="newList.bodyContent">
				{{newList.bodyContent}}
			</el-row>
		</div>
		<!-- 菜单导航 -->
		<meauDialog :meauValue="navigationvalue" @meauFn="menu"></meauDialog>
		
		<!-- 底部导航 -->
		<bottomNavigation></bottomNavigation>
	</div>
</template>


<script>
 
// 图片查询接口
import { selectPictureListById , selectContentListById } from '@/api/index.js'

// 头部组件
import headLogo from '@/components/headLogo.vue'
// 头部导航
import navigation from '@/components/navigation.vue'
// 底部导航
import bottomNavigation from '@/components/bottomNavigation.vue'
// 菜单导航
import meauDialog from '@/components/meauDialog.vue'
export default 
{
	data()
	{
		return{
			// 头部导航标题
			headTitle:'WHO WE ARE',
			// 头部导航图片
			headImg:'/index/team.png',
			
			// 导航值
			navigationvalue:false,
			
			newList:{},
		}
	},
	components:
	{
		headLogo,
		navigation,
		bottomNavigation,
		meauDialog
	},
	created()
	{
		this.getList();
	},
	methods:
	{
		// 头部传来的数据
		navigation(v)
		{
			this.navigationvalue=v;
			console.log(this.navigationvalue,'头部传递菜单导航')
		},
		// 遮罩层传回来的数据
		menu(v)
		{
			this.navigationvalue=v;
		},
		getList()
		{
			selectContentListById(
			{
			  chnanelType: 1,
			  isIndex: 0,
			  moduleType: 'MODULE_WHO'
			})
			.then((response)=>
			{
				this.newList=response.data.data[0];
				console.log(response.data.data)
			})
		}
	}
}
</script>

<style>
	.value{
		width: 100%;
	}
	.value2{
		width: 100%;
		height: 722px;
		position: relative;
	}
	.aboutUsPc{
		width: 60%;
		margin-left: 20%;
		margin-top: 50px;
	}
	.aboutUsPc-information{
		width: 100%;
		padding-right: 4%;
		border: solid 1px black;
		position: relative;
		padding-top: 30px;
	}
	.aboutUsPc-title{
		width: 30%;
		height: 50px;
		background-color: white;
		position: absolute;
		top: -20px;
		font-size: 30px;
		left: 35%;
	}
	.aboutUsPc-data{
		height: 150px;
		text-align: center;
		background-color: #f5f5f5;
		border-radius: 10px;
		margin-bottom: 20px;
	}
	.news-chainimg{
		width: 100px;
		height: 10px;
		margin-top: 20px;
	}
	.aboutUsPc-number{
		font-size: 2vw;
		margin-top: 30px;
	}
	.aboutUsPc-message{
		margin-top: 10px;
	}
	.values-message{
		text-align: left;
		line-height: 30px;
		margin-top: 30px;
		
		display: inline-block;
		text-align: justify;
		text-justify: distribute-all-lines;
		word-wrap:break-word;
		white-space: pre-wrap;
	}
	.values-message img{
		max-width: 100%;
	}
	.values-message video{
		max-width: 100%;
	}
</style>